<template>
  <div id="app" class="mdc-layout-grid">

  <!-- ESEMPIO blog_item.html -->
  <article class="container post-item mdc-card mdc-card--outlined  mdc-layout-grid__inner">

    <header class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-8-desktop">
        <h2><a href="/it/stories/2020/10/02/calvisano-eco-fedelt%C3%A0/">Calvisano Eco Fedeltà</a></h2>
        <h4>Comune di Calvisano</h4>
    </header>

    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-desktop blog-visual">
        <img src="./assets/q-city-logo.png" alt="img">
    </div>

    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 blog-lead">
        <p>Campagna di Eco-loyalty</p>
    </div>

    <footer class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 read-more">
        <a href="/it/stories/2020/10/02/calvisano-eco-fedeltà/">leggi »</a>
    </footer>

  </article>

  <!-- ESEMPIO paginazione -->

    <nav class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12  pagination">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-4 previous">
                <a href="?page=1">« precedente</a>
            </div>
        <div class="mdc-layout-grid__cell--span-4 indicator">
            <span class="current">
                Pagina 2 di 3
            </span>
        </div>
            <div class="mdc-layout-grid__cell--span-4 next">
                <a href="?page=3">prossima »</a>
            </div>
        </div>
    </nav>

  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
}
</script>

<style lang="scss">
@import "./scss/stories.theme";

#app {
  font-family: Roboto, Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 16px;
}
</style>
